import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count_overview: 0,
    count_overview_freiarbeit: 0,
    count_overview_kreativecke: 0,
    count_overview_montessori: 0,
    count_overview_wissenschaft: 0,
    transition_sound: new Audio(require('@/assets/audio/soundbits/Transition_1.mp3'))
  },
  mutations: {
    increment_overview (state) {
      state.count_overview++
    },
    increment_overview_freiarbeit (state) {
      state.count_overview_freiarbeit++
    },
    increment_overview_kreativecke (state) {
      state.count_overview_kreativecke++
    },
    increment_overview_montessori (state) {
      state.count_overview_montessori++
    },
    increment_overview_wissenschaft (state) {
      state.count_overview_wissenschaft++
    },
    reset_overview_montessori (state) {
      state.count_overview_montessori=0
    },
    page_transition (state) {
      state.transition_sound.play()
    }
  },
  actions: {
  },
  modules: {
  }
})
