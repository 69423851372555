import VuePlyrCustom from './vue-plyr-custom.vue'

VuePlyrCustom.install = (app, options = {}) => {
	if (options.plyr) {
		VuePlyrCustom.props.options.default = () => {
			return { ...options.plyr }
		}
	}

	app.component(VuePlyrCustom.name, VuePlyrCustom)
}

export default VuePlyrCustom
