<template>
  <div class="home">

    <div class="player" v-bind:class="{ play: status == 'play', paused: status == 'pause', ended: status == 'ended' }">
      <div>

        <div class="duration">Duration: {{ duration }}</div>

        <div class="infos">

          <h1 class="title home_title" v-if="duration > 45" v-bind:class="{ show: duration > 48 }">
            <img src="@/assets/app_logo_cloud.png" />
            <span>Rundgang <br> durchs <span class="color3">MORG</span></span>
            <div class="home_subtitle">...mit Texten aus<br> der Schreibwerkstatt</div>
          </h1>

          <button class="link-play" v-on:click="PlayVideo" v-bind:class="{ show: (status != 'play' && duration == 0) }">Video Starten</button>

          <router-link class="link home_link1 link-sm" v-if="duration > 8.5 && duration < 49" v-bind:class="{ show: duration > 9.5 && duration < 48 }" to="/uebersicht">Intro Überspringen</router-link>

          <router-link class="link link-thumbnail home_link2" v-bind:class="{ show: duration > 36.580 && duration < 39 }" to="/">
            <span>Klick mich</span>
            <vue-plyr-custom :options="{autoplay: true, autopause: false, volume: 0, muted: true, loop: {active: true}, fullscreen: {enabled: false}, controls: []}">
              <div data-plyr-provider="vimeo" data-plyr-embed-id="490801879"></div>
            </vue-plyr-custom>
          </router-link>

          <router-link class="link home_link3 link-btn" v-if="duration > 45" v-bind:class="{ show: duration > 49 }" to="/uebersicht">Das Morg entdecken</router-link>

          <div class="info_placeholder" v-bind:class="{ visible: status == 'ended' || duration > 49 }">
            <img src="@/assets/thumbnails/intro_end.jpg" />
          </div>

        </div>

        <vue-plyr-custom ref="plyr" :options="{autoplay: false, autopause: false, fullscreen: {enabled: false}, controls: [ 'play', 'progress', 'current-time', 'mute', 'volume' ]}">
          <div data-plyr-provider="vimeo" data-plyr-embed-id="490805090"></div>
        </vue-plyr-custom>

      </div>
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Overview',

  components: {
    Footer
  },

  data() {
    return {
      duration: null,
      status: '',
    };
  },

  mounted() {
    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.on('ready', () => this.onReady())
        this.$refs.plyr.player.on('play', () => this.onPlay())
        this.$refs.plyr.player.on('pause', () => this.onPause())
        this.$refs.plyr.player.on('ended', () => this.onEnded())
        this.$refs.plyr.player.on('timeupdate', () => this.videoTimeUpdated())
      }
    }
  },

  beforeDestroy() {

    this.$store.commit('page_transition')

    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.stop()
        this.$refs.plyr.player.destroy()
      }
    }

  },

  methods: {
    PlayVideo() {
      this.$refs.plyr.player.embed.play()
    },
    onReady() {
      // disable autostart
      // this.$refs.plyr.player.embed.play()
    },
    onPlay() {
      this.status = 'play'
    },
    onPause() {
      this.status = 'pause'
    },
    onEnded() {
      this.status = 'ended'
    },
    videoTimeUpdated: function() {
      if (this.$refs.plyr) {
        if (this.$refs.plyr.player) {
          this.duration = this.$refs.plyr.player.currentTime;
        }
      }

      if ( this.duration >= 36.580 && this.duration <= 36.730 ) {
        this.$root.playSoundbit(4)
      }

    }
  }

}
</script>