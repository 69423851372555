<template>
    <footer class="footer" id="footer">
      <a href="https://www.diakonie.cc/oberstufenrealgymnasium.html" target="_blank" ><img src="@/assets/logo_neg.png" /></a>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    title: String
  }
}
</script>