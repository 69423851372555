import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PathNotFound from '../views/PathNotFound.vue'

Vue.use(VueRouter)

const routes = [

  // Vimeo URLs
  // Intro: 490805090

  // Moderation Hauptmenü: 492097046

  // Moderation Wissenschaft: 491586099
  // Moderation Montesorri: 497263121
  // Moderation Kreative Ecke: 491585554
  // Moderation Freiarbeit: 493062590

  // Home
  {
    path: '/', name: 'Home', component: Home
  },

  // Overview
  {
    path: '/uebersicht', name: 'Overview', component: () => import( '../views/Overview.vue')
  },

  // Freiraum
  {
    path: '/freiarbeit', name: 'Freiarbeit', component: () => import( '../views/Overview.Freiraum.vue')
  },
  {
    path: '/kreativecke', name: 'KreativEcke', component: () => import( '../views/Overview.KreativEcke.vue')
  },
  {
    path: '/montessori', name: 'Montessori', component: () => import( '../views/Overview.Montessori.vue')
  },
  {
    path: '/wissenschaft', name: 'Wissenschaft', component: () => import( '../views/Overview.Wissenschaft.vue')
  },

  // Freiarbeit
  // Freiarbeitsbereich 1: https://player.vimeo.com/video/490803082
  // Freiarbeitsbereich 2: https://player.vimeo.com/video/490803270
  // Erfahrungsbericht: https://player.vimeo.com/video/490802634
  {
    path: '/video/freiarbeit1', name: 'VideoFreiarbeit1', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Freiarbeit 1',
      videoid: '490803082',
      backurl: '/freiarbeit',
      backname: 'Freiarbeit'
    }
  },
  {
    path: '/video/freiarbeit2', name: 'VideoFreiarbeit2', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Freiarbeit 2',
      videoid: '490803270',
      backurl: '/freiarbeit',
      backname: 'Freiarbeit'
    }
  },
  {
    path: '/video/erfahrungsbericht', name: 'Erfahrungsbericht', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Erfahrungsbericht',
      videoid: '490802634',
      backurl: '/freiarbeit',
      backname: 'Freiarbeit'
    }
  },

  // Kreativ Ecke
  // Tanz: https://player.vimeo.com/video/490807495
  // Theater: https://player.vimeo.com/video/490808167
  // Atelier: https://player.vimeo.com/video/490800841
  // Werkraum: https://player.vimeo.com/video/490808530
  {
    path: '/video/theater', name: 'Theater', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Theater',
      videoid: '490808167',
      backurl: '/kreativecke',
      backname: 'KreativEcke'
    }
  },
  {
    path: '/video/atelier', name: 'Atelier', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Atelier',
      videoid: '490800841',
      backurl: '/kreativecke',
      backname: 'KreativEcke'
    }
  },
  {
    path: '/video/tanzen', name: 'Tanzen', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Tanzen',
      videoid: '490807495',
      backurl: '/kreativecke',
      backname: 'KreativEcke'
    }
  },
  {
    path: '/video/werken', name: 'Werken', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Werken',
      videoid: '490808530',
      backurl: '/kreativecke',
      backname: 'KreativEcke'
    }
  },

  // Montessori
  // Stille: https://player.vimeo.com/video/490807155
  // Freiheit: https://player.vimeo.com/video/490803904
  // Frieden: https://player.vimeo.com/video/490804253
  // Disziplin: https://player.vimeo.com/video/490802250
  {
    path: '/video/stille', name: 'Stille', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Stille',
      videoid: '490807155',
      backurl: '/montessori',
      backname: 'Montessori'
    }
  },
  {
    path: '/video/freiheit', name: 'Freiheit', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Freiheit',
      videoid: '490803904',
      backurl: '/montessori',
      backname: 'Montessori'
    }
  },
  {
    path: '/video/frieden', name: 'Frieden', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Frieden',
      videoid: '490804253',
      backurl: '/montessori',
      backname: 'Montessori'
    }
  },
  {
    path: '/video/disziplin', name: 'Disziplin', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Disziplin',
      videoid: '490802250',
      backurl: '/montessori',
      backname: 'Montessori'
    }
  },

  // Wissenschaft
  // Informatik: https://player.vimeo.com/video/497263700
  // Bibliothek: https://player.vimeo.com/video/490801406
  // Chemie: https://player.vimeo.com/video/497263489
  // Image: https://player.vimeo.com/video/492542941
  {
    path: '/video/bibliothek', name: 'Bibliothek', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Bibliothek',
      videoid: '490801406',
      backurl: '/wissenschaft',
      backname: 'Wissenschaft'
    }
  },
  {
    path: '/video/kommunikation', name: 'Kommunikation', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Kommunikation',
      videoid: '497263700',
      backurl: '/wissenschaft',
      backname: 'Wissenschaft'
    }
  },
  {
    path: '/video/chemie', name: 'Chemie', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Chemie',
      videoid: '497263489',
      backurl: '/wissenschaft',
      backname: 'Wissenschaft'
    }
  },
  {
    path: '/video/globale-verantwortung', name: 'GlobaleVerantwortung', component: () => import( '../views/Video.vue'),
    meta: {
      videoname: 'Imagefilm Globale Verantwortung',
      videoid: '492542941',
      backurl: '/wissenschaft',
      backname: 'Wissenschaft'
    }
  },

  // 404
  {
    path: '/:pathMatch(.*)*', component: PathNotFound
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
